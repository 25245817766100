import handle from './index';

/**
 * @param url
 * @param page
 * @param perPage
 * @param search
 * @param sortBy
 * @param descending
 * @param params
 * @return {Promise<AxiosResponse>}
 */
async function getPaginated(url, page = 1, perPage = 10, search, sortBy, descending, params) {
  const result = await handle.get(url, {
    params: {
      page: page,
      perPage: perPage,
      search: search ? search : undefined,
      sortBy: sortBy ? sortBy : undefined,
      desc: typeof descending === 'undefined' ? undefined : (descending ? '1' : '0'),
      ...params,
    },
  });

  if (typeof result === 'string') throw result;

  return result.data;
}

export {
  getPaginated,
};
